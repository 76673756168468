<template lang="pug">
include ../../../configs/template
v-card
  v-card-title {{ $t('appGoogleAuth') }}
  v-card-text.text-start
    v-divider
    div.row.align-start
      div.col-sm-12.col-md-6
        p {{ $t('appSubTittleGoogleAuth2') }}
        p {{ $t('downloadAppGoogleAuthenticator') }}
          a(:href="LINK_AUTHENTICATOR_GOOGLE.GOOGLE_PLAY").text-primary Google Play
          span {{ $t('about') }}
          a(:href="LINK_AUTHENTICATOR_GOOGLE.APP_STORE").text-primary  App Store.
      div.col-sm-12.col-md-6
        v-img(src="@/assets/images/authenticator_app_light.svg" alt="altText" max-width="316")

    v-dialog(v-model="isCreateTwoStepAuth || $route.query.isAuthentication" scrollable max-width="600px").row
      template(#activator="{ on, attrs }")
        v-btn(outlined color="primary" v-bind="attrs" v-on="on" @click="getQRCodeTOTP")
          v-icon mdi-plus
          span {{ $t('btn.settingWayAuth') }}
      v-card.pa-5
        v-card-title {{ $t('settingApplicatinForVerification') }}
        v-card-text(v-if="step === 1")
          ul
            li {{ $t('touchInApp') }}
              b +
            li {{ $t('chooseOption') }}
              b {{ $t('scanQrCode') }}
          div.m-auto.my-8
            v-skeleton-loader(v-if="isLoading.qr" type="image" max-width="166" max-height="166").ma-auto
            v-img(v-else :src="qr" alt="qr" max-width="166" max-height="166").ma-auto
        v-card-text(v-else)
          +field-validation('body.device_name','nameDevice','["required"]')
          +field-validation('body.token','code','["required"]')(v-mask="'######'")
        v-card-actions(:class="step === 1 ? 'justify-end' : 'justify-space-between'")
            v-btn(v-if="step === 2" text color="primary" @click="step = 1") {{ $t('btn.back') }}
            div.d-flex.justify-end
              v-btn(text color="primary" @click="setCancelModal") {{ $t('btn.cancel') }}
              v-btn(v-if="step === 1" text color="primary" @click="step = 2") {{ $t('btn.next') }}
              v-btn(v-if="step === 2" text color="primary" @click="setVerifiTOTP") {{ $t('btn.save') }}
</template>
<script>
import { TOTP } from '@ananasbear/auth'
import { LINK_AUTHENTICATOR_GOOGLE } from '@/configs/constants'
import { totpAuthCode } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
const initBody = () => ({
  token: null,
  device_name: null
})
export default {
  name: 'GoogleAuthenticator',
  data () {
    return {
      LINK_AUTHENTICATOR_GOOGLE,
      step: 1,
      isCreateTwoStepAuth: false,
      isLoading: {
        qr: false
      },
      body: initBody(),
      qr: null
    }
  },
  computed: {
    ...mapState({
      username: state => state.main.user.username
    })
  },
  validations () { return totpAuthCode() },
  mounted () {
    if (this.$route.query.isAuthentication) this.getQRCodeTOTP()
  },
  methods: {
    ...mapActions(['getListDeviceWithTOTPForUser']),
    async getQRCodeTOTP () {
      this.isLoading.qr = true
      this.body.device_name = this.username
      const response = await TOTP.register()
      this.qr = `data:image/gif;base64,${response}`
      this.isLoading.qr = false
    },
    async setVerifiTOTP () {
      if (this.$v.$invalid) return this.$v.$touch()
      const response = await TOTP.verify(this.body).then(resp => resp).catch(error => error)
      if (response.status) {
        this.$router.replace({ query: {} })
        this.$notification.success('notify.success.totp')
        this.setCancelModal()
        this.getListDeviceWithTOTPForUser()
      }
    },
    setCancelModal () {
      this.$router.replace({ query: {} })
      this.isCreateTwoStepAuth = false
      this.body = initBody()
      this.step = 1
      this.$v.$reset()
    }
  }
}
</script>
<style lang="sass" scoped>
.iconPageAuth
  background: no-repeat url('/assets/images/palitra_icons_google.png') 0 -2126px
  background-size: 304px 3143px
  width: 100%
  height: 100%
</style>
